<template>
  <DrawerView :visible="visible" :width="560" :title="targetIndex == null ? '添加设备' : '编辑设备'" @confirm="handleOk" @cancel="handleCancel">
    <a-form-model ref="basicForm" :model="businessInfoDto" :label-col="labelCol" :wrapper-col="wrapperCol" :colon="false">
      <a-form-model-item label="设备图片" prop="devicePicture" :rules="{ required: true, validator: uploadFileChange }">
        <uploadFile :number="1" :showUploadList="false" :uploadFileType="'image'" :defaultParams="{
          dir: 'picture',
        }" :needMemo="false" v-model="devicePictList" />
      </a-form-model-item>
      <a-form-model-item label="设备名称" prop="deviceName" :rules="{ required: true, message: '请输入设备名称' }">
        <a-input style="width: 320px;height: 38px;" placeholder="请输入" v-model="businessInfoDto.deviceName"></a-input>
      </a-form-model-item>
      <a-form-model-item label="设备品牌">
        <a-input style="width: 320px;height: 38px;" placeholder="请输入" v-model="businessInfoDto.deviceBrand"></a-input>
      </a-form-model-item>
      <a-form-model-item label="设备数量" prop="deviceCount" :rules="{ required: true, message: '请输入设备数量' }">
        <a-input-number placeholder="请输入" style="width: 320px;height: 38px;"
          v-model="businessInfoDto.deviceCount"></a-input-number>

      </a-form-model-item>
      <a-form-model-item label="设备型号">
        <a-input style="width: 320px;height: 38px;" placeholder="请输入" v-model="businessInfoDto.deviceModel"></a-input>
      </a-form-model-item>
      <a-form-model-item label="门幅">
        <a-input style="width: 320px;height: 38px;" placeholder="请输入" v-model="businessInfoDto.deviceTitle"></a-input>
      </a-form-model-item>
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="handleOk('save')" :loading="loading" type="primary">确定</a-button>
    </template>
  </DrawerView>
</template>

<script>
import DrawerView from '@/components/plugins/drawerView.vue'
import { mapGetters } from 'vuex'
import { postQueryAction, postAction } from '@/api/manage'
import uploadFile from '@/components/plugins/uploadFile.vue'

export default {
  name: 'moreInfoForm',
  components: {
    DrawerView,
    uploadFile
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      visible: false,
      loading: false,
      businessInfoDto: {},
      info: {},
      basicData: {},
      devicePictList: [],
      targetIndex: null,
      deviceList: [],
    }
  },
  created() {

  },
  methods: {
    ...mapGetters(['setUserInfo']),
    // 自定义上传附件校验
    uploadFileChange(rule, value, callback) {
      if (this.devicePictList.length === 0) {
        return callback('请上传设备图片')
      } else {
        return true
      }
    },
    handleEdit(item, index) {
      this.targetIndex = index
      item && (this.businessInfoDto = { ...item })
      if (item.devicePicture) {
        let nameStr = item.devicePicture.split('/images/')
        this.devicePictList = [
          {
            url: item.devicePicture,
            srcFileName: nameStr[nameStr.length - 1],
          },
        ]
      }
      const { tradeIdentity } = this.setUserInfo()
      this.tradeIdentity = tradeIdentity
      this.handleInitData()
      this.visible = true
    },
    handleInitData() {
      if (!this.setUserInfo().businessId) return
      postQueryAction('/business/queryByBusinessId', { businessId: this.setUserInfo().businessId }).then(res => {
        const { success, data } = res
        if (success) {
          this.deviceList = data.device || []
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.businessInfoDto = {}
      this.loading = false
      this.deviceList = []
      this.info = {}
      this.basicData = {}
      this.devicePictList = []
    },
    handleOk() {
      const that = this
      this.$refs.basicForm.validate(valid => {
        if (valid) {
          this.loading = true
          let params = {
            ...this.businessInfoDto,
            devicePicture: this.devicePictList[0] ? this.devicePictList[0].url : '',
          }
          delete params.detail
          if (this.targetIndex == null) {
            this.deviceList.push(params)
          } else {
            this.deviceList[this.targetIndex] = params
          }
          postAction('/business/save/device', [
            ...this.deviceList
          ])
            .then(res => {
              const { message, success } = res
              that.loading = false
              if (success) {
                that.$message.success('保存成功')
                this.$emit('ok')
                that.handleCancel()
              } else {
                that.$message.warning(message)
              }
            })
            .catch(err => {
              that.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
// ::v-deep .ant-form-item {
//   margin-bottom: 14px;
//   padding-right: 120px;
// }
::v-deep .ant-input {
  width: 320px;
  height: 38px;
  line-height: 38px;
}

::v-deep .ant-btn {
  width: 97px;
  height: 38px;
}

::v-deep .ant-input-number-input {
  height: 38px;
}
</style>