import { render, staticRenderFns } from "./capacityVo.vue?vue&type=template&id=47a3aefb&scoped=true"
import script from "./capacityVo.vue?vue&type=script&lang=js"
export * from "./capacityVo.vue?vue&type=script&lang=js"
import style0 from "./capacityVo.vue?vue&type=style&index=0&id=47a3aefb&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a3aefb",
  null
  
)

export default component.exports