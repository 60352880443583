<!--
 * @Description: Description
 * @Author: ZHANG ZHEN
 * @Date: 2021-05-21 15:33:24
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-03-16 18:24:23
-->
<template>
  <a-drawer
    :title="title"
    :width="width"
    :placement="position"
    :getContainer="getContainer"
    :destroyOnClose="true"
    :maskClosable="maskClosable"
    @close="$_handleCancel"
    :visible="visible"
    :afterVisibleChange="(e) => handleChangeVisible(e)"
  >
    <!-- 数据内容区域 -->
    <div class="contentView">
      <slot></slot>
    </div>
    <div class="bottomBar">
      <!-- 按钮区域 -->
      <slot name="btnArea"></slot>
    </div>
  </a-drawer>
</template>

<script>
export default {
  props: {
    maskClosable: {
      type: Boolean,
      required: false,
      default: true,
    },
    name: {
      type: String,
      required: false,
      default: '新建',
    },
    title: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      required: false,
      default: '60%',
    },
    position: {
      type: String,
      required: false,
      default: 'right',
    },
    getContainer: {
      type: String,
      required: false,
      default: 'body',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    ModelType: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    // 对外抛出cancel事件
    $_handleCancel() {
      this.$emit('update:visible', false)
      this.$emit('cancel')
    },
    // 对外抛出 confirm事件
    $_handleConfirm() {
      this.$emit('update:visible', false)
      this.$emit('confirm')
    },
    handleChangeVisible(e) {
      this.$emit('changeVisible', e)
    }
  },
}
</script>
<style lang="less" scoped>
::v-deep .ant-drawer-body {
  padding: 24px 24px 68px 32px;
}
::v-deep .ant-drawer-title {
  line-height: 24px;
}
::v-deep .ant-drawer-header {
  padding: 24px 24px 24px 32px;
  border-bottom: none;
  &::after {
    display: block;
    content: '';
    position: absolute;
    left: 32px;
    bottom: 0;
    width: calc(100% - 56px);
    height: 1px;
    background-color: #F2F3F5;
  }
}
.bottomBar {
  position: absolute;
  z-index: 9999;
  left: 0;
  bottom: 0;
  width: 100%;
  // border-top: 1px solid #e9e9e9;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 10px 16px;
  background: #fff;
  text-align: right;

  ::v-deep .ant-btn {
    min-width: 98px;
    height: 38px;
    font-weight: 500;
    &+.ant-btn {
      margin-left: 8px;
    }

    // &.ant-btn-primary {
    //   color: #fff;
    // }
  }
}
.contentView {
  height: 100% ;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>