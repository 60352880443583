import { render, staticRenderFns } from "./drawerView.vue?vue&type=template&id=82c4017c&scoped=true"
import script from "./drawerView.vue?vue&type=script&lang=js"
export * from "./drawerView.vue?vue&type=script&lang=js"
import style0 from "./drawerView.vue?vue&type=style&index=0&id=82c4017c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82c4017c",
  null
  
)

export default component.exports