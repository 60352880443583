<!--
 * @Description: 获取图形验证码展示
 * @Author: zhang zhen
 * @Date: 2023-05-10 17:12:56
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-03-06 10:28:39
 * @FilePath: /page-sass/src/components/tools/captcha.vue
-->
<template>
  <a-modal
      title="图形验证码"
      :visible="visible"
      @ok="handleOk"
      :width="280"
      @cancel="handleCancel"
      centered
    >
      <a-input placeholder="图形验证码" v-model="captchaCode">
        <template slot="addonAfter">
          <img :src="imgPath" alt="" class="captchaImg" @click="handleSendCaptcha()">
        </template>
      </a-input>
    </a-modal>
</template>

<script>
  import { downGetFiles,downFiles } from '@/api/manage';
  export default {
    name: 'captcha',
    props: ['phone'],
    data() {
      return {
        visible: false,
        imgPath: '',
        captchaCode: ''
      }
    },
    methods: {
      handleOpenModal(phone = null) {
        console.log(!phone || !this.phone)
        if (!phone && !this.phone) return this.$message.warning({
        content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">手机号不能为空，短信发送失败</span>,
        icon: (h) => <img src="/noPass.png" width="21" height="21" />
      })
          this.phone = phone
          this.handleSendCaptcha(phone);
          this.visible = true
      },
      /* 获取code */
      handleSendCaptcha(phone) {
        downFiles('/img/captcha', {
          phone: phone || this.phone
        }).then(res => {
          let blob = new Blob([res]);   // 返回的文件流数据
          let url = window.URL.createObjectURL(blob);  // 将他转化为路径
          this.imgPath = url
        })
      },
      /* 关闭 */
      handleCancel() {
        this.visible = false;
        this.imgPath = null;
        this.captchaCode = ''
      },
      /* 取消 */
      handleOk() {
        if (!this.captchaCode) return this.$message.warning('请输入图形验证码')
        this.$emit('ok', this.captchaCode)
        this.handleCancel()
      },
    },
  }
</script>

<style lang="less" scoped>
.captchaImg {
  width: 80px;
  height: 28px;
  cursor: pointer;
}
</style>